import type { ParsedVideo } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export type VideoProps = {
  video: ParsedVideo;
  videoProps?: {
    className?: string;
    style?: Record<string, string>;
  };
  wrapperProps?: Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'dangerouslySetInnerHTML'
  >;
};

export const Video: FC<VideoProps> = ({ videoProps, video, wrapperProps }) => {
  const { className: wrapperClassName, ...restWrapperProps } =
    wrapperProps ?? {};
  const primarySourceHtml = video.src
    ? `<source src="${video.src}" type="${video.type}" />`
    : '';
  const fallbackSourcesHtml = (video.fallbacks ?? []).map((fallback) =>
    fallback.src
      ? `<source src="${fallback.src}" type="${fallback.type}" />`
      : ''
  );
  const sourcesHtml = [primarySourceHtml, ...fallbackSourcesHtml].join('');
  const videoClassName = clsx(['size-full', videoProps?.className]);
  const videoAttributes = {
    autoplay: video.playerProps?.autoplay ?? true,
    loop: video.playerProps?.loop ?? true,
    muted: video.playerProps?.muted ?? true,
    controls: video.playerProps?.controls ?? false,
  };
  const videoAttributesHtml = [
    `class="${videoClassName}"`,
    'playsinline',
    ...Object.entries(videoAttributes).reduce((prev, [key, value]) => {
      if (!value) return prev;

      return [...prev, key];
    }, [] as string[]),
  ];
  const videoStylesMap: Record<string, string> = { ...videoProps?.style };

  if (!videoProps?.style?.['background-color'] && video.backgroundColor)
    videoStylesMap['background-color'] = video.backgroundColor;

  const videoStyle = Object.entries(videoStylesMap)
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ');

  if (videoStyle) videoAttributesHtml.push(`style="${videoStyle}"`);

  const videoAttributesHtmlString = videoAttributesHtml.join(' ');

  return (
    <div
      {...restWrapperProps}
      className={clsx(['size-full', wrapperClassName])}
      dangerouslySetInnerHTML={{
        __html: `<video ${videoAttributesHtmlString}>${sourcesHtml}</video>`,
      }}
    />
  );
};
