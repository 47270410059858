import {
  ImageInterestPoints,
  type ImageInterestPointsProps,
} from '@/components/ImageInterestPoints';
import type {
  IFrame,
  ParsedImage,
  ParsedVideo,
  ProductImageMap,
  SiteTemplate,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type {
  DetailedHTMLProps,
  FC,
  IframeHTMLAttributes,
  ImgHTMLAttributes,
} from 'react';
import { BsBag } from 'react-icons/bs';

import type { VideoProps } from '../Video';
import { Video } from '../Video';

type ImageInterestPointButtonIconProps = {
  className?: string;
  color?: string;
};

const ImageInterestPointButtonIcon: FC<ImageInterestPointButtonIconProps> = ({
  className,
  color,
}) => {
  return (
    <BsBag
      className={clsx(['size-14', 'transition', 'lg:size-20', className])}
      color={color}
    />
  );
};

export type MediaProps =
  | {
      type: 'iframe';
      value: IFrame;
      props?: Partial<
        DetailedHTMLProps<
          IframeHTMLAttributes<HTMLIFrameElement>,
          HTMLIFrameElement
        >
      >;
    }
  | {
      type: 'image';
      value: ParsedImage;
      props?: Partial<
        DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
      >;
    }
  | {
      type: 'video';
      value: ParsedVideo;
      props?: Partial<Omit<VideoProps, 'video'>>;
    }
  | {
      type: 'productImageMap';
      value: ProductImageMap<SiteTemplate>;
      props?: Partial<
        Omit<ImageInterestPointsProps, 'points' | 'image' | 'buttonColors'>
      >;
    };

export const Media: FC<MediaProps> = ({ type, value, props }) => {
  switch (type) {
    case 'iframe': {
      const { allow, src, title } = value;
      const { className, ...restProps } = props ?? {};

      return (
        <iframe
          {...restProps}
          allow={allow}
          src={src}
          title={title}
          className={clsx('size-full', className)}
        />
      );
    }
    case 'image': {
      if (!value) return null;

      const { alt, eager, src } = value;
      const { className, ...restProps } = props ?? {};
      const loading = eager ? 'eager' : 'lazy';

      return (
        <img
          {...restProps}
          alt={alt}
          loading={loading}
          src={src}
          className={clsx([
            'size-full',
            'object-cover',
            'object-center',
            className,
          ])}
        />
      );
    }
    case 'video': {
      return <Video {...props} video={value} />;
    }
    case 'productImageMap': {
      const { buttonColors, image, points } = value;
      const {
        containerClassName,
        onPointClick,
        pointValue = null,
      } = props ?? {};

      return (
        <ImageInterestPoints
          containerClassName={clsx([
            'w-max',
            'h-full',
            'left-1/2',
            '-translate-x-1/2',
            '[@media(min-width:1400px)]:w-full',
            containerClassName,
          ])}
          image={image}
          buttonColors={buttonColors.background}
          // onPointClick={(newPointValue) => {
          //   onPointClick?.(newPointValue);

          //   if (pointValue === selectedImageInterestPoint) {
          //     setIsSelectedImageInterestPointCardOpen((prev) => !prev);
          //   } else {
          //     setIsSelectedImageInterestPointCardOpen(true);
          //   }

          //   setSelectedImageInterestPoint(newPointValue);
          // }}
          onPointClick={onPointClick}
          pointValue={
            // typeof pointValue !== 'undefined'
            //   ? pointValue
            //   : selectedImageInterestPoint
            pointValue
          }
          points={points.map((point, pointIndex) => ({
            colors: point.colors.background,
            position: point.position,
            title: point.product.name,
            value: `${pointIndex}`,
            Icon: ({ selected }) => {
              const buttonInactiveIconClass =
                buttonColors.icon.unselected === 'light'
                  ? 'text-white'
                  : 'text-primary';
              const buttonActiveIconClass =
                buttonColors.icon.selected === 'light'
                  ? 'text-white'
                  : 'text-primary';
              const iconClass = selected
                ? buttonActiveIconClass
                : buttonInactiveIconClass;
              const iconColor =
                (selected
                  ? point.colors?.icon?.selected
                  : point.colors?.icon?.unselected) || undefined;

              return (
                <ImageInterestPointButtonIcon
                  className={iconClass}
                  color={iconColor}
                />
              );
            },
          }))}
        />
      );
    }
    default: {
      return null;
    }
  }
};
