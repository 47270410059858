import type { ImageInterestPointsProps } from '@/components/ImageInterestPoints';
import { Strings } from '@/constants/strings';
import { formatCurrency } from '@/utils/stringFormat';
import type {
  ParsedMedia,
  Product,
  ProductImageMap,
  SiteTemplate,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { HTMLMotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import { useState } from 'react';
import type {
  DetailedHTMLProps,
  FC,
  IframeHTMLAttributes,
  ImgHTMLAttributes,
} from 'react';
import { MdList } from 'react-icons/md';

import { Media } from '../Media';
import type { VideoProps } from '../Video';
import { ImageInterestPointCard, TaggedItemsModal } from './components';

export type FmMediaProps = HTMLMotionProps<'div'> & {
  media?: ParsedMedia;
  backHref?: string;
  currency?: string;
  imageProps?: Partial<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  >;
  iframeProps?: Partial<
    DetailedHTMLProps<
      IframeHTMLAttributes<HTMLIFrameElement>,
      HTMLIFrameElement
    >
  >;
  videoProps?: Partial<Omit<VideoProps, 'video'>>;
  productImageMapProps?: Partial<
    Omit<ImageInterestPointsProps, 'points' | 'image' | 'buttonColors'>
  >;
};

export const FmMedia: FC<FmMediaProps> = ({
  backHref,
  currency,
  media,
  imageProps,
  iframeProps,
  videoProps,
  productImageMapProps,
  initial = 'hidden',
  transition = { delay: 0.5 },
  whileInView = 'visible',
  viewport = { once: true },
  ...restProps
}) => {
  const [selectedImageInterestPoint, setSelectedImageInterestPoint] = useState<
    string | null
  >(null);
  const [
    isSelectedImageInterestPointCardOpen,
    setIsSelectedImageInterestPointCardOpen,
  ] = useState(false);
  const [isTaggedItemsModalOpen, setIsTaggedItemsModalOpen] = useState(false);

  if (!media || media.type === 'none' || !media.value) return null;

  const productImageMap =
    media?.type === 'productImageMap' ? media.value : null;
  const selectedImageInterestPointIndex = Number.parseInt(
    selectedImageInterestPoint ?? '',
    10
  );
  const activePoint = productImageMap?.points[
    selectedImageInterestPointIndex
  ] as ProductImageMap<SiteTemplate>['points'][number] | undefined;
  const activeProduct = activePoint?.product as
    | Product<'homeDecor1'>
    | undefined;
  const activeProductHref = activeProduct
    ? `/products/${activeProduct.crmId}${backHref ? `?back=${backHref}` : ''}`
    : '#';
  const [activeProductImage] = activeProduct?.gallery ?? [];

  const handlePointClick: ImageInterestPointsProps['onPointClick'] = (
    newPointValue
  ) => {
    if (newPointValue === selectedImageInterestPoint) {
      setIsSelectedImageInterestPointCardOpen((prev) => !prev);
    } else {
      setIsSelectedImageInterestPointCardOpen(true);
    }

    setSelectedImageInterestPoint(newPointValue);
  };

  let mediaComponent: JSX.Element | null = null;

  switch (media.type) {
    case 'iframe': {
      mediaComponent = (
        <Media type={media.type} value={media.value} props={iframeProps} />
      );
      break;
    }
    case 'image': {
      mediaComponent = (
        <Media type={media.type} value={media.value} props={imageProps} />
      );
      break;
    }
    case 'video': {
      mediaComponent = (
        <Media type={media.type} value={media.value} props={videoProps} />
      );
      break;
    }
    case 'productImageMap': {
      mediaComponent = (
        <Media
          type={media.type}
          value={media.value}
          props={{
            onPointClick: handlePointClick,
            pointValue: selectedImageInterestPoint,
            ...productImageMapProps,
          }}
        />
      );
      break;
    }
    default: {
      break;
    }
  }

  return (
    <motion.div
      {...restProps}
      initial={initial}
      transition={transition}
      whileInView={whileInView}
      viewport={viewport}
    >
      {mediaComponent}

      <TaggedItemsModal
        title={
          Strings.components.pageSections.hero.taggedItemsModal.title['en-US']
        }
        content={
          Strings.components.pageSections.hero.taggedItemsModal.content['en-US']
        }
        items={productImageMap?.points.map((point) => {
          const [firstImage] = point.product.gallery ?? [];

          return {
            href: `/products/${point.product.crmId}${backHref ? `?back=${backHref}` : ''}`,
            image: firstImage,
            name: point.product.name,
          };
        })}
        open={isTaggedItemsModalOpen}
        onClose={() => setIsTaggedItemsModalOpen(false)}
      />

      {/* Show all tags button */}
      {!!productImageMap?.points.length && (
        <button
          title="Show all tagged items"
          className={clsx([
            'group',
            'rounded-full',
            'absolute',
            'bottom-10',
            'left-10',
            'lg:bottom-20',
            'lg:left-20',
            'p-[4px]',
            'transition-all',
            'shadow-md',
            'border',
            'bg-button-secondary-bg',
            'border-button-secondary-border',
            'hover:bg-button-secondary-hover-bg',
            'hover:border-button-secondary-hover-border',
          ])}
          onClick={() => setIsTaggedItemsModalOpen(true)}
        >
          <MdList
            className={clsx([
              'text-button-secondary-text',
              'group-hover:text-button-secondary-hover-text',
            ])}
            size={20}
          />
        </button>
      )}

      {!!productImageMap && (
        <div
          className={clsx([
            'foobar',
            'size-full',
            'absolute',
            'top-0',
            'left-0',
            'transition',
            !isSelectedImageInterestPointCardOpen && [
              'opacity-0',
              'pointer-events-none',
              'bg-black/0',
            ],
            isSelectedImageInterestPointCardOpen && [
              'opacity-100',
              'bg-black/50',
            ],
          ])}
        >
          {/* Desktop interest point card */}
          <div
            className={clsx([
              'top-1/2',
              'left-1/2',
              '-translate-x-1/2',
              '-translate-y-1/2',
              'w-full',
              'max-w-[345px]',
              'md:max-w-[560px]',
              'lg:max-w-[345px]',
              'absolute',
              'transition-all',
              'hidden',
              'lg:block',
            ])}
          >
            <ImageInterestPointCard
              onClose={() => setIsSelectedImageInterestPointCardOpen(false)}
              image={activeProductImage}
              href={activeProductHref}
              orientation={{ desktop: 'column' }}
            >
              {!!activeProduct && (
                <div
                  className={clsx(
                    'w-full',
                    'p-24',
                    'text-center',
                    'text-14',
                    'lg:text-16'
                  )}
                >
                  <div>
                    <p>{activeProduct?.name}</p>

                    <p>
                      {formatCurrency(activeProduct.value, {
                        options: { currency },
                      })}
                    </p>
                  </div>
                </div>
              )}
            </ImageInterestPointCard>
          </div>

          {/* Mobile interest point card */}
          <div
            className={clsx([
              'max-h-[90%]',
              'w-[90%]',
              'max-w-[345px]',
              'md:max-w-[560px]',
              'lg:max-w-[345px]',
              'absolute',
              'transition-all',
              'lg:hidden',
              'left-1/2',
              '-translate-x-1/2',
              '-translate-y-1/2',
              !isSelectedImageInterestPointCardOpen && [
                'opacity-0',
                'top-full',
                'pointer-events-none',
              ],
              isSelectedImageInterestPointCardOpen && [
                'opacity-100',
                'top-1/2',
              ],
            ])}
          >
            <ImageInterestPointCard
              onClose={() => setIsSelectedImageInterestPointCardOpen(false)}
              image={activeProductImage}
              href={
                activeProduct
                  ? `/products/${activeProduct.crmId}${backHref ? `?back=${backHref}` : ''}`
                  : '#'
              }
              orientation={{
                mobile: 'row',
                desktop: 'column',
              }}
            >
              {!!activeProduct && (
                <div
                  className={clsx(
                    'w-full',
                    'p-24',
                    'text-center',
                    'text-14',
                    'lg:text-16'
                  )}
                >
                  <div>
                    <p>{activeProduct?.name}</p>

                    <p>
                      {formatCurrency(activeProduct.value, {
                        options: { currency },
                      })}
                    </p>
                  </div>
                </div>
              )}
            </ImageInterestPointCard>
          </div>
        </div>
      )}
    </motion.div>
  );
};
