import { CloseButton } from '@/components/general/CloseButton';
import { MissingImage } from '@/components/general/MissingImage';
import type { ParsedImage } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import { type PropsWithChildren } from 'react';

export type ImageInterestPointCardProps = PropsWithChildren<{
  href?: string;
  image?: ParsedImage;
  onClose?: () => void;
  orientation?: {
    mobile?: 'row' | 'column';
    desktop?: 'row' | 'column';
  };
}>;

export const ImageInterestPointCard = ({
  children,
  href,
  image,
  onClose,
  orientation,
}: ImageInterestPointCardProps) => {
  return (
    <div
      className={clsx([
        'w-full',
        'max-w-[345px]',
        'md:max-w-[560px]',
        'lg:max-w-[345px]',
        'flex-1',
        'bg-background-primary',
        'shadow-md',
      ])}
    >
      {!!onClose && (
        <CloseButton
          onClick={onClose}
          className={clsx(['absolute', 'top-[-12px]', 'right-[-12px]'])}
        />
      )}

      <a
        href={href}
        className={clsx([
          'flex',
          'items-center',
          'border',
          'border-gray-3',
          { 'flex-col': orientation?.mobile === 'column' },
          { 'flex-row': orientation?.mobile !== 'column' },
          { 'lg:flex-col': orientation?.desktop === 'column' },
          { 'lg:flex-row': orientation?.desktop !== 'column' },
        ])}
      >
        <div className={clsx(['aspect-square', 'overflow-hidden', 'w-full'])}>
          {!!image && (
            <img
              src={image.src}
              alt={image.alt}
              loading="lazy"
              className={clsx(['size-full', 'object-cover', 'object-center'])}
            />
          )}

          {!image && <MissingImage />}
        </div>

        {children}
      </a>
    </div>
  );
};
